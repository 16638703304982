export default {
    'Compressor' : `
        <svg width="113" height="94" viewBox="0 0 113 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.051 72.7636V18.1962L111.762 1.4018V90.2882L34.051 72.7636Z" fill="#C4C4C4" stroke="black" stroke-width="2"/>
            <path d="M33.9952 73.0845L1 91.7676V2.70977L33.9952 18.2203V73.0845Z" fill="#C4C4C4" stroke="black" stroke-width="2"/>
        </svg>
    `,
    'Steam Turbine' : `
        <svg width="74" height="60" viewBox="0 0 74 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.8363 45.2418L1.89697 56.6624V2.12137L36.8363 14.9696V45.2418Z" fill="#FFAF64" stroke="black" stroke-width="2"/>
            <path d="M36.8922 45.5494V15.4923L72.8036 3.06448V57.6222L36.8922 45.5494Z" fill="#FFAF64" stroke="black" stroke-width="2"/>
        </svg>
    `,
    'Condenser' : `
        <svg width="126" height="68" viewBox="0 0 126 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.73328" y="1.82776" width="90.3484" height="65.0741" rx="12" fill="#5CBCF5" stroke="black" stroke-width="2"/>
            <rect x="92.1375" y="32.9345" width="14.5255" height="3.83253" fill="#DEDEDE" stroke="black" stroke-width="2"/>
            <rect x="106.719" y="26.1858" width="18.2813" height="21.2859" rx="3" fill="#5CBCF5" stroke="black" stroke-width="2"/>
        </svg>
    `,
    'separator' : `
        <svg width="35" height="19" viewBox="0 0 35 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.81799" y="1.12994" width="32.0231" height="16.4697" fill="#DEDEDE" stroke="black" stroke-width="2"/>
        </svg>
    `
}