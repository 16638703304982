































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AdamCardConfig } from 'adam.ui-core/dist/src/lib/components/card';
import { Unit, Status, Component as UnitComponent, BarrierGroup } from '@/models';
import { FeedItem } from '@/models/feed';

import Chart from '@/components/chart/chart.vue';
import Feed from '@/components/feed/feed.vue';

import PlantComponents from './components';
import BarrierGroups from '@/components/barrier/barrier.vue';
import Bowtie from '@/components/bowtie/bowtie.vue';

@Component({
    name: 'plant',
    components: {
        Chart,
        Feed,
        BarrierGroups,
        Bowtie
    },
})

export default class PlantUnit extends Vue {

    @Prop() readonly unit!: Unit
    @Prop() readonly plantId!: string

    @Watch('$route.params.componentId')
    removeFadeOut(comp: any): void {
        if(!comp) {
            this.fadeOut = false;
        }
    }

    private chartData(): Array<any> {
        const selectedComponent = this.$store.getters['bowtie/getCurrentComponent']
        const source = this.selected.id ? selectedComponent : this.unit
        const status = source.status ?? {}

        const dataPoints = Object.keys(status).map(k => status[k].score)

        return [{
            name: 'status',
            data: [...dataPoints]
        }]
    }

    get powerPlantUnitFeed(): FeedItem[] {
        return this.$store.getters['bowtie/getPowerPlantUnitFeed'];
    }

    private get PlantComponents(): any {
        return PlantComponents;
    }

    private selected: UnitComponent = {};
    private fadeOut = false;

    private cardConfig: AdamCardConfig = {
        cardClass: '',
        headerClass: 'adam-card-header',
        contentClass: '',
        actionClass: 'align-end',
        hasAction: true,
    };

    customIcon(data: any): any {
        if(!(Object.keys(data).length === 0 && data.constructor === Object)) {
            const color = data[this.lastUpdate(data)].status;
            switch(color) {
            case 'Yellow':
                return 'ic-warn';
            case 'Red':
                return 'ic-delete';
            case 'Green':
                return 'ic-check';
            default:
                return 'ic-none';
            }
        }
    }

    customClass(data: any): any {
        if(!(Object.keys(data).length === 0 && data.constructor === Object)) {
            const color = data[this.lastUpdate(data)].status;
            switch(color) {
            case 'Yellow':
                return 'warning';
            case 'Red':
                return 'error';
            case 'Green':
                return 'success';
            default:
                return 'gray';
            }
        }
    }

    lastUpdate(date: Status[]): string {
        const keys = Object.keys(date);
        return keys[keys.length - 1];
    }

    async handleClick(component: UnitComponent): Promise<void> {
        if (this.selected.id && this.selected.id === component.id){
            this.selected = {};
        } else {
            this.selected = component;
        }

        if (this.selected.id) {
            this.fadeOut = true;
        } else {
            this.fadeOut = false;
        }

        if (this.selected.id) {
            this.$store.commit('bowtie/setInactive');

            if (this.$route.params.componentId) {
                this.$store.commit('bowtie/removeLastItem');
            }

            await this.$store.commit('bowtie/setBreadcrumbs', { 
                label: this.selected.name, 
                url: `/${this.$route.params.plantId}/unit/${this.$route.params.plantUnitId}/component/${this.selected.id}`,
                isActive: true
            });
            await this.$router.push({ name: 'BarrierGroups', params: { 
                plantUnitId: this.$route.params.plantUnitId,
                componentId: this.selected.id.toString(),
            }});

            await this.$store.dispatch('bowtie/loadCurrentComponent', this.selected.id);
        } else {
            this.$store.commit('bowtie/removeLastItem');
            this.$router.push({ name: 'Unit', params: { 
                plantUnitId: this.$route.params.plantUnitId,
            }});      
            this.$store.commit('bowtie/setCurrentComponent', {});      
        }

    }

    notEmptyObject(obj: any): any {
        return Object.keys(obj).length
    }

    get component(): Promise<BarrierGroup[]> {
        return this.$store.getters['bowtie/getCurrentComponent'].barrierGroups;
    }

    async created(): Promise<void> {
        let unitId = +this.$route.params.plantUnitId;
        if (isNaN(unitId)) {
            unitId = this.$store.getters['bowtie/getUnits'][0].id;

            this.$router.replace({ params: { plantUnitId: unitId.toString() } });
        }

        if (this.$route.params.componentId) {
            this.fadeOut = true;
        }

        await this.$store.dispatch('bowtie/loadCurrentUnit', unitId);
        await this.$store.dispatch('bowtie/loadPowerPlantUnitFeed', unitId);

        const componentId = +this.$route.params.componentId;
        if (!isNaN(componentId)) {
            await this.$store.dispatch('bowtie/loadCurrentComponent', componentId);
        }
    }
}

