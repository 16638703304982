



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { BarrierGroup } from '@/models';
import Bowtie from '@/components/bowtie/bowtie.vue';

@Component({
    name: 'barrier-groups',
    components: {
        Bowtie
    }
})
export default class BarrierGroups extends Vue {
    @Prop() readonly barrierGroups!: Array<BarrierGroup>;
    @Prop() readonly componentId!: string;

    private selected: any;

    private showBarrier = true;

    get currentBarrierGroup(): any {
        return this.$store.getters['bowtie/getCurrentBarrierGroup'];
    }

    customClass(data: any): any {
        if (!(Object.keys(data).length === 0 && data.constructor === Object)) {
            const color = data[this.lastUpdate(data)].status;
            switch (color) {
            case 'Yellow':
                return 'warning';
            case 'Red':
                return 'error';
            case 'Green':
                return 'success';
            default:
                return 'gray';
            }
        }
    }

    isActive(id: any): any {
        if (id == this.$route.params.barrierId) {
            return 'active';
        }
    }

    async getBarrierDetail(barrier: any): Promise<any> {
        this.selected = barrier;

        this.$store.commit('bowtie/setInactive');

        if (this.$route.params.barrierId) {
            this.$store.commit('bowtie/removeLastItem');
        }

        this.$store.commit('bowtie/setBreadcrumbs', {
            label: this.selected.name,
            url: `/${this.$route.params.plantId}/unit/${this.$route.params.plantUnitId}/component/${this.$route.params.componentId}/barrier-group/${this.selected.id}`,
            isActive: true
        });

        this.$router.push({
            name: 'BarrierGroupsDetails',
            params: {
                plantUnitId: this.$route.params.plantUnitId,
                componentId: this.$route.params.componentId,
                barrierId: this.selected.id
            }
        });

        await this.$store.dispatch(
            'bowtie/loadCurrentBarrierGroup',
            this.selected.id
        );
    }

    lastUpdate(date: any): any {
        const keys = Object.keys(date);
        return keys[keys.length - 1];
    }

    created(): any {
        if (this.$route.params.barrierId) {
            this.$store.dispatch(
                'bowtie/loadCurrentBarrierGroup',
                this.$route.params.barrierId
            );
        }
    }
}
