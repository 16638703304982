import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/pages/home/home.vue';
import Unit from '@/pages/unit/unit.vue';
import Tree from '@/pages/treelist/tree-list.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/:plantId?',
        name: 'Home',
        component: Home,
    },
    {
        path: '/:plantId/unit/:plantUnitId',
        name: 'Unit',
        component: Unit,
        props: true,
    },
    {
        path: '/:plantId/unit/:plantUnitId/component/:componentId?',
        name: 'BarrierGroups', // 
        component: Unit, // 
        props: true,

    },
    {
        path: '/:plantId/unit/:plantUnitId/component/:componentId?/barrier-group/:barrierId?',
        name: 'BarrierGroupsDetails',
        component: Unit,
        props: true
    },
    {
        path: '/:plantId/unit/:plantUnitId/component/:componentId?/barrier-group/:barrierId?/barrier/:barrierDetailsId?',
        name: 'BarrierDetails',
        component: Tree,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to): any {
        if (to.name === 'BarrierDetails') {
            return { x: 0, y: 0 };
        }
    },
});

export default router;
