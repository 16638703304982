import APIConnection from '@/utils/api-connection';
import { Barrier, BarrierGroup, Component, Plant, Unit } from '@/models';
import { PlantWithDetails } from '@/models/plant';
import { UnitWithDetails } from '@/models/unit';
import { ComponentWithDetails } from '@/models/component';
import { BarrierGroupWithDetails } from '@/models/barrier-group';
import { BarrierWithDetails } from '@/models/barrier';
import { FeedItem } from '@/models/feed';

/**
 * @name BowtieService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class BowtieService {
    private api: APIConnection = new APIConnection(process.env.VUE_APP_API_BASE_URL);

    public async getPlants(): Promise<Plant[]> {
        const { data: { result } } =
            (await this.api.get(`plants`));
        return result;
    }

    public async getPlant(id: number): Promise<Plant> {
        const { data: { result } } =
            (await this.api.get(`plants/${id}`));
        return result;
    }

    public async getPowerPlantFeed(id: number): Promise<FeedItem[]> {
        const { data: { result } } =
            (await this.api.get(`plants/${id}/feed`));
        return result;
    }

    public async getPowerPlantUnitFeed(id: number): Promise<FeedItem[]> {
        const { data: { result } } =
            (await this.api.get(`units/${id}/feed`));
        return result;
    }

    public async getPlantWithDetails(id: number): Promise<PlantWithDetails> {
        const { data: { result } } =
            (await this.api.get(`plants/${id}/details`));
        return result;
    }

    public async getUnit(id: number): Promise<Unit> {
        const { data: { result } } =
            (await this.api.get(`units/${id}`));
        return result;
    }

    public async getUnitWithDetails(id: number): Promise<UnitWithDetails> {
        const { data: { result } } =
            (await this.api.get(`units/${id}/details`));
        return result;
    }

    public async getComponent(id: number): Promise<Component> {
        const { data: { result } } =
            (await this.api.get(`components/${id}`));
        return result;
    }

    public async getComponentWithDetails(id: number): Promise<ComponentWithDetails> {
        const { data: { result } } =
            (await this.api.get(`components/${id}/details`));
        return result;
    }

    public async getBarrierGroup(id: number): Promise<BarrierGroup> {
        const { data: { result } } =
            (await this.api.get(`barrierGroups/${id}`));
        return result;
    }

    public async getBarrierGroupWithDetails(id: number): Promise<BarrierGroupWithDetails> {
        const { data: { result } } =
            (await this.api.get(`barrierGroups/${id}/details`));
        return result;
    }

    public async getBarrier(id: number): Promise<Barrier> {
        const { data: { result } } =
            (await this.api.get(`barrier/${id}`));
        return result;
    }

    public async getBarrierWithDetails(id: number): Promise<BarrierWithDetails> {
        const { data: { result } } =
            (await this.api.get(`barriers/${id}/codes`));
        return result;
    }
}
