















import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'plant-select',
})
export default class PlantSelect extends Vue {

    get plants(): any[] {
        return this.$store.getters['bowtie/getPlants'];
    }

    async selected(item: any): Promise<void> {
        if(item != this.$route.params.plantId) {
            this.$store.commit('bowtie/setSelectedPlant', item)
            this.$store.commit('bowtie/emptyBreadcrumbs')

            this.$router.push(`/${item}`);

            await this.$store.dispatch('bowtie/loadCurrentPlant', item);
            await this.$store.dispatch('bowtie/loadPowerPlantFeed', item);
        }
    }
}
