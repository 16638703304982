import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import filters from '@/filters';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import AppSecurity from './utils/app-security';

import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false;
Vue.use(filters);
Vue.use(VueI18n);
Vue.use(VueApexCharts);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts);

library.add(faCaretRight);

const lang = 'en-GB';
const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
});

// TODO: uncomment this once auth and the identity client is set up.
// Needs to be imported from the utils folder.
AppSecurity.initialize();

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
