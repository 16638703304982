















import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: 'header-toolbar',
})
export default class HeaderToolbar extends Vue {

    private get breadcrumb(): any[] {
        return this.$store.getters['bowtie/getBreadcrumbs'];
    }

    private navigate(url: string): void {

        const indexOfUrl = this.breadcrumb.map(item => item.url).indexOf(url)
        this.$store.commit('bowtie/sliceBreadcrumbs', indexOfUrl);

        if (url) {
            this.$router.push(url)
        }
    }
}
