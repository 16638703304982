















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdamCardConfig } from 'adam.ui-core/dist/src/lib/components/card';
import { Unit, Status } from '@/models';

import Chart from '@/components/chart/chart.vue';

@Component({
    name: 'cards',
    components: {
        Chart,
    },
})
export default class Cards extends Vue {

    @Prop() readonly units!: Array<Unit>

    private chartData(unit: Unit): Array<any> {
        const status = unit.status ?? {}
        const dataPoints = Object.keys(status).map(k => status[k].score)

        return [{
            name: 'status',
            data: [...dataPoints]
        }]
    }

    private cardConfig: AdamCardConfig = {
        cardClass: '',
        headerClass: 'adam-card-header',
        contentClass: '',
        actionClass: 'align-end',
        hasAction: true,
    };

    notEmptyObject(obj: any): any {
        return Object.keys(obj).length
    }

    customIcon(data: any): string {
        const color = data[this.lastUpdate(data)].status;
        switch(color) {
        case 'Yellow':
            return 'ic-warn';
        case 'Red':
            return 'ic-delete';
        case 'Green':
            return 'ic-check';
        default:
            return 'ic-none';
        }
    }

    customClass(data: any): any {
        const color = data[this.lastUpdate(data)].status;
        switch(color) {
        case 'Yellow':
            return 'warning';
        case 'Red':
            return 'error';
        case 'Green':
            return 'success';
        default:
            return 'gray';
        }
    }

    lastUpdate(date: Status[]): string {
        const keys = Object.keys(date);
        return keys[keys.length - 1];
    } 

    triggerCard(data: any): void {
        this.$store.commit('bowtie/setInactive');

        this.$store.commit('bowtie/setBreadcrumbs', { 
            label: data.name, 
            url: `/${this.$route.params.plantId}/unit/${data.id}`,
            isActive: true
        });
        this.$router.push({ name: 'Unit', params: { plantId: this.$route.params.plantId ,plantUnitId: data.id }});
    }
}

