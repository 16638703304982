













import { Component, Vue } from 'vue-property-decorator';
import PlantUnit from '@/components/plant/plant.vue';
import BarrierGroups from '@/components/barrier/barrier.vue';
import { Unit as UnitModel } from '@/models';
import { PlantWithDetails } from '@/models/plant';

@Component({
    name: 'unit',
    components: {
        PlantUnit,
        BarrierGroups
    },
})
export default class Unit extends Vue {

    get unit(): UnitModel {
        return this.$store.getters['bowtie/getCurrentUnit'];
    }

    get currentPlantName(): PlantWithDetails {
        return this.$store.getters['bowtie/getCurrentPlant'].name;
    }

    async created(): Promise<void> {
        await this.$store.dispatch('bowtie/loadPlants');
    }
}
