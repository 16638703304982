export default {
    chart: {
        height: 150,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    toolbar: {
        show: false,
        tools: {
            download: false,
        },
    },
    tooltip: {
        enabled: false,
    },
    grid: {
        row: {
            opacity: 0.5
        },
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: false,
            },
        },
    },
    colors: [
        '#0078DC', '#00A7F5', '#0067cb', '#229AFE', '#5CBCF5',
    ],
    stroke: {
        width: 2,
        curve: 'straight',
    },
    xaxis: {
        categories: [''],
    },
    yaxis: {
        labels: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
    },
}
