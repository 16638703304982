import BaseService from '@/utils/base-service';
import { Application } from '@/models';
import { ApiResponseList } from '@/models/api-response';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService extends BaseService {
    constructor() {
        super('use-case', process.env.VUE_APP_IDENTITY_AUTHORITY + '/api/v1');
    }

    public async getApps(): Promise<ApiResponseList<Application>> {
        return (await this.api.get(`${this.endpoint}/list`)).data;
    }
}
