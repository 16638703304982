























import { Component, Prop, Vue } from 'vue-property-decorator';
import options from './chart.config';

@Component({
    name: 'chart'
})
export default class Chart extends Vue {
    
    @Prop() data!: Array<any>;

    private display = false;

    private get options(): any {
        return options
    }

    private get hasData(): boolean {
        return this.data[0] &&
            this.data[0].data &&
            this.data[0].data.length
    }

    mounted(): void {
        this.$nextTick(() => {
            this.display = true
        })
    }
}

