




































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'tree-list'
})
export default class TreeList extends Vue {

    @Prop() readonly currentBarrier!: any

    get items(): any {
        const currentBarrier = this.currentBarrier;
        if( currentBarrier.codes ) {
            currentBarrier.codes = currentBarrier.codes.map((item: any) => ({
                ...item,
                show: false,
            }))
        }

        if ( currentBarrier.codes ) {
            currentBarrier.codes.map((item: any) => {
                const sapMaintenance: any[] = [];
                const sapDefect: any[] = [];
                const pi: any[] = [];
                item.tags.map((subItem: any) => {
                    switch(subItem.type) {
                    case 'SapMaintenance':
                        sapMaintenance.push(subItem);
                        break;
                    case 'SapDefect':
                        sapDefect.push(subItem);
                        break;
                    case 'Pi':
                        pi.push(subItem);
                    }
                })

                item.sapMaintenance = sapMaintenance;
                item.sapDefect = sapDefect;
                item.pi = pi;
            }
            )
        }

        return currentBarrier;
    }

    customColor(data: any): any {
        if(!(Object.keys(data).length === 0 && data.constructor === Object)) {
            const color = data[this.lastUpdate(data)].status;
            switch(color) {
            case 'Yellow':
                return 'warning';
            case 'Red':
                return 'error';
            case 'Green':
                return 'success';
            default:
                return 'gray';
            }
        }
    }

    customClass(data: any): any {
        if(!(Object.keys(data).length === 0 && data.constructor === Object)) {
            switch(data) {
            case 'Yellow':
                return 'warning';
            case 'Red':
                return 'error';
            case 'Green':
                return 'success';
            default:
                return 'gray';
            }
        }
    }

    lastUpdate(date: any): any {
        const keys = Object.keys(date);
        return keys[keys.length-1];
    } 

    notEmptyObject(obj: any): any {
        return Object.keys(obj).length
    }

    openTree(detail: any): any {
        detail.show = !detail.show;
        return 'open';
    }
}

