export default {
    'Alarms' : `
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.596 16.9823C19.6903 16.0092 17.9958 14.5453 17.9958 9.75C17.9958 6.10781 15.442 3.19219 11.9986 2.47688V1.5C11.9986 0.671719 11.3274 0 10.4995 0C9.67173 0 9.00048 0.671719 9.00048 1.5V2.47688C5.55705 3.19219 3.0033 6.10781 3.0033 9.75C3.0033 14.5453 1.30876 16.0092 0.403138 16.9823C0.121888 17.2847 -0.00279962 17.6461 -0.000455872 18C0.00470038 18.7687 0.607982 19.5 1.50423 19.5H19.4949C20.3911 19.5 20.9949 18.7687 20.9995 18C21.0019 17.6461 20.8772 17.2842 20.596 16.9823ZM3.16501 17.25C4.1597 15.9389 5.2472 13.7658 5.25236 9.77719C5.25236 9.76781 5.24955 9.75937 5.24955 9.75C5.24955 6.85031 7.59986 4.5 10.4995 4.5C13.3992 4.5 15.7495 6.85031 15.7495 9.75C15.7495 9.75937 15.7467 9.76781 15.7467 9.77719C15.7519 13.7662 16.8394 15.9394 17.8341 17.25H3.16501ZM10.4995 24C12.1552 24 13.4981 22.657 13.4981 21H7.50095C7.50095 22.657 8.84392 24 10.4995 24Z" fill="#606060"/>
    </svg>
    `,
    'Maintanance' : `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.7995 5.11424C23.6945 4.69096 23.1648 4.54752 22.8563 4.85596L19.3707 8.34158L16.1888 7.81143L15.6587 4.62955L19.1443 1.14392C19.4546 0.833612 19.3051 0.304862 18.879 0.198925C16.6581 -0.351388 14.2126 0.241581 12.4768 1.97689C10.6187 3.83502 10.1073 6.52846 10.8807 8.87689L0.878066 18.8796C-0.29334 20.051 -0.29334 21.9503 0.878066 23.1217C2.04947 24.2931 3.94885 24.2931 5.12025 23.1217L15.1145 13.1275C17.4638 13.9108 20.1521 13.3938 22.0224 11.5235C23.7601 9.7858 24.3521 7.33658 23.7995 5.11424ZM2.99963 22.1252C2.37854 22.1252 1.87463 21.6213 1.87463 21.0002C1.87463 20.3786 2.37854 19.8752 2.99963 19.8752C3.62072 19.8752 4.12463 20.3786 4.12463 21.0002C4.12463 21.6213 3.62072 22.1252 2.99963 22.1252Z" fill="#606060"/>
    </svg>
    `,
    'View' : `
    <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 4.59375C14.448 4.60249 13.9 4.69128 13.3729 4.85742C13.6167 5.29159 13.7465 5.78181 13.75 6.28125C13.75 6.66906 13.6745 7.05307 13.528 7.41136C13.3814 7.76965 13.1665 8.0952 12.8957 8.36942C12.6249 8.64365 12.3033 8.86117 11.9495 9.00958C11.5956 9.15799 11.2163 9.23438 10.8333 9.23438C10.34 9.23091 9.85587 9.09946 9.42706 8.85258C9.08874 10.0406 9.12817 11.3062 9.53976 12.4703C9.95136 13.6343 10.7142 14.6377 11.7204 15.3384C12.7265 16.0391 13.9248 16.4014 15.1457 16.3742C16.3665 16.347 17.5479 15.9315 18.5226 15.1867C19.4973 14.4418 20.2158 13.4054 20.5764 12.2241C20.9369 11.0428 20.9212 9.77663 20.5316 8.60487C20.1419 7.43311 19.398 6.41519 18.4052 5.69529C17.4124 4.9754 16.2211 4.59002 15 4.59375ZM29.8187 9.73008C26.9942 4.15025 21.4026 0.375 15 0.375C8.59737 0.375 3.00414 4.15289 0.181222 9.73061C0.0620767 9.96923 0 10.2329 0 10.5003C0 10.7677 0.0620767 11.0313 0.181222 11.2699C3.0057 16.8497 8.59737 20.625 15 20.625C21.4026 20.625 26.9958 16.8471 29.8187 11.2694C29.9379 11.0308 29.9999 10.7671 29.9999 10.4997C29.9999 10.2323 29.9379 9.96871 29.8187 9.73008ZM15 18.0938C9.86195 18.0938 5.15153 15.1934 2.60778 10.5C5.15153 5.80664 9.86143 2.90625 15 2.90625C20.1385 2.90625 24.8484 5.80664 27.3922 10.5C24.8489 15.1934 20.1385 18.0938 15 18.0938Z" fill="#606060"/>
    </svg>
    `
}