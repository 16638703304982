

















import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import PlantSelect from '@/components/plantselect/plantselect.vue';
import Toolbar from '@/components/toolbar/toolbar.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import AppSecurity from './utils/app-security';
import './main.less';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Toolbar,
        PlantSelect,
        snackbar: Snackbar,
        loader: Loader,
    },
})
export default class App extends Vue {
    private signedIn = true;
    private appSecurity = new AppSecurity();

    private async mounted(): Promise<void> {
        this.signedIn = true;
        // TODO: enable and implement app specific permission parsing when auth and identity client is set up.
        // this.signedIn = (await this.appSecurity.getSignedIn() as boolean);
        // if (this.signedIn) {
        //     await this.appSecurity.parseAndSetPermissions();
        // }
    }
}
