













import { Component, Vue } from 'vue-property-decorator';
import Cards from '@/components/card/card.vue';
import PlantUnit from '@/components/plant/plant.vue'
import Feed from '@/components/feed/feed.vue';

import { PlantWithDetails } from '@/models/plant';
import { FeedItem } from '@/models/feed';

@Component({
    name: 'home',
    components: {
        Cards,
        PlantUnit,
        Feed,
    },
})
export default class Home extends Vue {

    get currentPlant(): PlantWithDetails {
        return this.$store.getters['bowtie/getCurrentPlant'];
    }

    get powerPlantFeed(): FeedItem[] {
        return this.$store.getters['bowtie/getPowerPlantFeed'];
    }

    get selectedPlant(): any {
        return this.$store.getters['bowtie/getSelectedPlant'];
    }

    async created(): Promise<void> {
        await this.$store.dispatch('bowtie/loadPlants');

        let plantId = +this.$route.params.plantId;
        if (isNaN(plantId)) {
            plantId = this.$store.getters['bowtie/getPlants'][0].id;

            this.$router.replace({ params: { plantId: plantId.toString() } });
        }

        await this.$store.dispatch('bowtie/loadCurrentPlant', plantId);
        await this.$store.dispatch('bowtie/loadPowerPlantFeed', plantId);
    }
}
