import { EventBus } from '@/utils';

import { Component, Plant, Unit } from '@/models';
import { BowtieService } from '@/services';

import { Module } from 'vuex';
import { PlantWithDetails } from '@/models/plant';
import { UnitWithDetails } from '@/models/unit';
import { ComponentWithDetails } from '@/models/component';
import { FeedItem } from '@/models/feed';
import { BarrierGroupWithDetails } from '@/models/barrier-group';
import { BarrierWithDetails } from '@/models/barrier';

const bowtie: Module<any, any> = {
    //
    state: {
        plants: [],
        powerPlantFeed: [],
        powerPlantUnitFeed: [],
        currentPlant: {},
        currentUnit: {},
        currentComponent: {},
        currentBarrierGroup: {},
        currentBarrier: {},
        breadCrumbs: [],
        selectedPlant: 1
    },

    //
    mutations: {
        setPlants(state, plants: Plant[]): void {
            const editedPlants = plants.map(plant => ({
                label: plant.name,
                id: plant.id,
                value: plant.id
            }))

            state.plants = editedPlants;

        },
        setCurrentPlant(state, currentPlant: PlantWithDetails): void {
            state.currentPlant = currentPlant;
        },
        setCurrentUnit(state, currentUnit: UnitWithDetails): void {
            state.currentUnit = currentUnit;
        },
        setCurrentComponent(state, currentComponent: ComponentWithDetails): void {
            state.currentComponent = currentComponent;
        },
        setPowerPlantFeed(state, powerPlantFeed: FeedItem[]): void {
            state.powerPlantFeed = powerPlantFeed;
        },
        setPowerPlantUnitFeed(state, powerPlantUnitFeed: FeedItem[]): void {
            state.powerPlantUnitFeed = powerPlantUnitFeed;
        },
        setCurrentBarrierGroup(state, currentBarrierGroup: BarrierGroupWithDetails): void {
            state.currentBarrierGroup = currentBarrierGroup;
        },
        setCurrentBarrier(state, currentBarrier: BarrierWithDetails): void {
            state.currentBarrier = currentBarrier;
        },
        setBreadcrumbs(state, breadCrumbs: any): void {
            state.breadCrumbs.push(breadCrumbs);
        },
        removeLastItem(state): void {
            state.breadCrumbs.pop()
        },
        sliceBreadcrumbs(state, index: any): void {            
            state.breadCrumbs = state.breadCrumbs.slice(0, index+1);
        },
        setSelectedPlant(state, selected: any): void {
            state.selectedPlant = selected;
        },
        emptyBreadcrumbs(state): void {
            state.breadCrumbs = [];
        },
        setInactive(state): void {
            state.breadCrumbs.map((item: any) => item.isActive = false);
        }
    },

    //
    actions: {
        async loadPlants({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getPlants();

                if (!result) {
                    return;
                }
                commit('setPlants', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadPowerPlantFeed({ commit }, plantId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getPowerPlantFeed(plantId);

                if (!result) {
                    return;
                }
                commit('setPowerPlantFeed', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadPowerPlantUnitFeed({ commit }, unitId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getPowerPlantUnitFeed(unitId);

                if (!result) {
                    return;
                }
                commit('setPowerPlantUnitFeed', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadCurrentPlant({ commit }, plantId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getPlantWithDetails(plantId);

                if (!result) {
                    return;
                }
                commit('setCurrentPlant', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadCurrentUnit({ commit }, unitId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getUnitWithDetails(unitId);

                if (!result) {
                    return;
                }
                commit('setCurrentUnit', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadCurrentComponent({ commit }, componentId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getComponentWithDetails(componentId);

                if (!result) {
                    return;
                }
                commit('setCurrentComponent', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadCurrentBarrierGroup({ commit }, barrierGroupId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getBarrierGroupWithDetails(barrierGroupId);

                if (!result) {
                    return;
                }

                commit('setCurrentBarrierGroup', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async loadCurrentBarrier({ commit }, barrierId: number): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const result = await (new BowtieService).getBarrierWithDetails(barrierId);

                if (!result) {
                    return;
                }

                commit('setCurrentBarrier', result);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingData');
            } finally {
                commit('loading', null, { root: true });
            }
        }
    },

    //
    getters: {
        getPlants(state): Plant[] {
            return state.plants;
        },
        getCurrentPlant(state): PlantWithDetails {
            return state.currentPlant;
        },
        getUnits(state): Unit[] {
            return state.currentPlant.units;
        },
        getCurrentUnit(state): UnitWithDetails {
            return state.currentUnit;
        },
        getComponents(state): Component[] {
            return state.currentUnit.components;
        },
        getCurrentComponent(state): ComponentWithDetails {
            return state.currentComponent;
        },
        getPowerPlantFeed(state): FeedItem[] {
            return state.powerPlantFeed;
        },
        getPowerPlantUnitFeed(state): FeedItem[] {
            return state.powerPlantUnitFeed;
        },
        getCurrentBarrierGroup(state): BarrierGroupWithDetails {
            return state.currentBarrierGroup;
        },
        getCurrentBarrier(state): BarrierWithDetails {
            return state.currentBarrier;
        },
        getBreadcrumbs(state): any {
            return state.breadCrumbs;
        },
        getSelectedPlant(state): any {
            return state.selectedPlant;
        }
    },
    namespaced: true
}

export default bowtie
