import Oidc from 'oidc-client';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export default class AppSecurity {
    public static initialize(): void {
        if (AppSecurity.initialized) {
            return;
        }

        AppSecurity.mgr = new Oidc.UserManager({
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            authority: process.env.VUE_APP_IDENTITY_AUTHORITY,
            client_id: 'bowtie',
            redirect_uri: `${window.location.origin}/authorization/callback.html`,
            response_type: 'code',
            scope: 'openid profile email COODE.BowTieAPI IdentityServerApi',
            post_logout_redirect_uri: `${window.location.origin}/authorization/logged-out.html`,
            silent_redirect_uri: `${window.location.origin}/authorization/silent.html`,
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 10,
            loadUserInfo: true,
        });

        // uncomment this to enable user manager logging.
        // Oidc.Log.logger = console;
        // Oidc.Log.level = Oidc.Log.INFO;

        AppSecurity.mgr.events.addUserLoaded(function (user: any) {
            // console.log('New User Loaded：', arguments);
            // console.log('Acess_token: ', user.access_token);
        });

        AppSecurity.mgr.events.addAccessTokenExpiring(function () {
            // console.log('AccessToken Expiring：', arguments);
        });

        AppSecurity.mgr.events.addAccessTokenExpired(function () {
            // console.log('AccessToken Expired：', arguments);
            // alert('Session expired. Going out!');
            AppSecurity.mgr.signoutRedirect().then((resp: any) => {
                // console.log('signed out', resp);
            }).catch((err: any) => {
                // console.log(err);
            });
        });

        AppSecurity.mgr.events.addSilentRenewError(function () {
            // console.error('Silent Renew Error：', arguments);
        });

        AppSecurity.mgr.events.addUserSignedOut(function () {
            // alert('Going out!');
            // console.log('UserSignedOut：', arguments);
            AppSecurity.mgr.signoutRedirect().then((resp: any) => {
                // console.log('signed out', resp);
            }).catch((err: any) => {
                console.log(err);
            });
        });

        AppSecurity.initialized = true;
    }

    /* tslint:enable */

    private static initialized: boolean = false;
    private static mgr: any;

    // Renew the token manually
    public renewToken() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.signinSilent().then((user: any) => {
                if (user == null) {
                    this.signIn();
                } else {
                    return resolve(user);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Get the user who is logged in
    public getUser() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Check if there is any user logged in
    public getSignedIn() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(false);
                } else {
                    return resolve(true);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Redirect of the current window to the authorization endpoint.
    public signIn() {
        AppSecurity.mgr.signinRedirect().catch((err: any) => {
            console.log(err);
        });
    }

    // Redirect of the current window to the end session endpoint
    public signOut() {
        AppSecurity.mgr.signoutRedirect().then((resp: any) => {
            console.log('signed out', resp);
        }).catch((err: any) => {
            throw err;
        });
    }

    // Get the profile of the user logged in
    public getProfile() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.profile);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Get the token id
    public getIdToken() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.id_token);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Get the session state
    public getSessionState() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.session_state);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Get the access token of the logged in user
    public getAcessToken() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.access_token);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Takes the scopes of the logged in user
    public getScopes() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.scopes);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }

    // Get the user roles logged in
    public getRole() {
        return new Promise((resolve, reject) => {
            AppSecurity.mgr.getUser().then((user: any) => {
                if (user == null) {
                    this.signIn();
                    return resolve(null);
                } else {
                    return resolve(user.profile.role);
                }
            }).catch((err: any) => {
                return reject(err);
            });
        });
    }
}
