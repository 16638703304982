import { DateTime } from 'luxon';

export default {
    install(Vue: any): void {
      
        //
        Vue.filter('timeAgo', function (date: string): string {
            const units: any = [
                'year',
                'month',
                'week',
                'day',
                'hour',
                'minute',
                'second',
            ];

            const timeAgo = (date: string): string => {
                const dateTime = DateTime.fromISO(date).toLocal();
                const diff = dateTime.diffNow().shiftTo(...units);
                const unit = units.find((unit: any) => diff.get(unit) !== 0) || 'second';

                const relativeFormatter = new Intl.RelativeTimeFormat('en', {
                    numeric: 'auto',
                }
                );
                return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
            };
            return timeAgo(date);
        }),

        Vue.filter('formatTime', function (date: string): any {
            const dateItem = DateTime.fromISO(date);
            const minuteFormat = dateItem.minute < 10 ? `0${dateItem.minute}`: dateItem.minute;
            const monthFormat = dateItem.month < 10 ? `0${dateItem.month}`: dateItem.month;
            const timeFormat = `${dateItem.hour}:${minuteFormat}`;
            const dateFormat = `${dateItem.day}-${monthFormat}-${dateItem.year}`
            return `${timeFormat} on ${dateFormat}`
        }) 
    }
}
