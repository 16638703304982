





































































































































































import Options from './options';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'bowtie'
})
export default class Bowtie extends Vue {

    @Prop() readonly currentBarrier!: any

    get items(): any {
        return this.currentBarrier
    }
    
    customColor(data: any): any {
        if(!(Object.keys(data).length === 0 && data.constructor === Object)) {
            const color = data[this.lastUpdate(data)].status;
            switch(color) {
            case 'Yellow':
                return 'warning';
            case 'Red':
                return 'error';
            case 'Green':
                return 'success';
            default:
                return 'gray';
            }
        }
    }

    lastUpdate(date: any): any {
        const keys = Object.keys(date);
        return keys[keys.length-1];
    } 

    private size  = 1000;

    private get Options(): any {
        return Options;
    }

    get leftWidth(): number {
        // 80% of the size
        return this.size * 0.8;
    }
    get rightWidth(): number {
        // 20% of the size
        return this.size * 0.2;
    }
    get height(): number {
        return this.size;
    }
    get halfSize(): number {
        return this.size * 0.5;
    }
    get distance(): number {
        // distance between two array items on the horizon
        return Math.round(this.height / this.items.barriers.length);
    }
    get radius(): number {
        return (this.rightWidth*0.5)
    }
    get viewbox(): string {
        return '0 0 ' + this.size + ' ' + this.size;
    }

    calculateYPos(i: number): any {
        // new values for x2 and x3
        const x = this.distance * i + this.distance * 0.5;
        return Math.round(x);
    }

    notEmptyObject(obj: any): any {
        return Object.keys(obj).length
    }

    accessTree(item: any): any {

        this.$store.commit('bowtie/setInactive');

        this.$store.commit('bowtie/setBreadcrumbs', { 
            label: item.name,
            url: `/${this.$route.params.plantId}/unit/${this.$route.params.plantUnitId}/component/${this.$route.params.componentId}/barrier-group/${this.$route.params.barrierId}/barrier/${item.id}`,
            isActive: true
        });

        this.$router.push({ name: 'BarrierDetails', params: { 
            plantUnitId: this.$route.params.plantUnitId,
            componentId: this.$route.params.componentId,
            barrierId: this.$route.params.barrierId,
            barrierDetailsId: item.id,
        }});
    }

    created(): any {
        return this.currentBarrier;
    }
}

