



































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'feed'
})
export default class Feed extends Vue {
    
    @Prop() list!: Array<any>;

    private get feed(): any {
        return this.list;
    }
}

