









import { Component, Vue } from 'vue-property-decorator';
import TreeList from '@/components/tree-list/tree-list.vue';

@Component({
    name: 'Tree',
    components: {
        TreeList
    },
})
export default class Tree extends Vue {

    get currentBarrier(): any {
        return this.$store.getters['bowtie/getCurrentBarrier'];
    }

    async created(): Promise<any> {
        await this.$store.dispatch('bowtie/loadPlants');
        this.$store.dispatch('bowtie/loadCurrentBarrier', this.$route.params.barrierDetailsId);
        return this.currentBarrier;
    }
}
